$secondaryTextColor: var(--base-dark-color-3);
$buttonCarouselColor: var(--base-gray-color);
$buttonSeeMore: var(--base-dark-color);

$baseLightColor: var(--base-light-color);
$baseDangerColor: var(--base-danger-color);

.product-box-container {
    position: relative;
    margin: 1rem;
    

    .btn-quick-look {
        width: 2%;
        min-width: 30px;
        position: absolute;
        z-index: 1000;

        top: 5px;
        right: 5px;

        cursor: pointer;
    }

    .product-box {
        height: 100%;
        border: none;
        // border-radius: 6px;
        // box-shadow: 0px 11px 20px 2px rgba(0, 54, 165, 0.1);
        overflow: hidden;
        transition: 0.1s all;
    
        background-color: rgba(0, 0, 0, 0);
        color: inherit;
        text-decoration: none;
    
        cursor: pointer;
    
        .product-box-body {
            padding-left: 0;
            padding-right: 0;
    
            .product-box-name {
                font-weight: 600;
                font-size: 12px;
                min-height: 40px;

                text-align: center;
            }
    
            .product-box-desc {
                .product-box-first-row {
                    display: flex;
                    align-items: center;
                    // justify-content: space-between;
                    justify-content: center;
    
                    .product-box-category {
                        font-size: 11px;
                        color: $secondaryTextColor;
                    }
    
                    .product-box-stock {
                        font-size: 9px;
                        color: $secondaryTextColor;
                    }
                }
    
                .product-box-price {
                    text-align: center;
                    font-size: 13px;
    
                    &.discounted {
                        font-size: 12px;
                        text-decoration: line-through $baseDangerColor;
                        -webkit-text-decoration-line: line-through;
                        -webkit-text-decoration-color: $baseDangerColor;
                    }
                }
            }
        }
    
        &-image {
            width: 100%;
            box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.1);
            // border-radius: 6px;
        }
    }
}