$baseGrayColor: var(--base-gray-color);
$baseLightColor2: var(--base-light-color-2);
$baseDarkColor: var(--base-dark-color);

$baseDangerColor: var(--base-danger-color);

.shopping-cart-container {
    padding: 1rem 2.5rem 0rem 1.5rem;
    min-width: 550px;

    .shopping-cart-header {
        font-family: 'Helvetica';
        border-bottom: 1px solid $baseGrayColor;
        // width: 85%;
        padding: 1rem 0 0.5rem;
        
        .shopping-cart-title {
            font-size: 2rem;
            text-transform: uppercase;
            font-weight: 700;
            margin-bottom: 0;
        }
    }

    .shopping-cart-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 0.3rem;

        .shopping-cart-list {
            max-height: 75%;
            overflow-y: auto;
            
            display: grid;
            grid-template-rows: repeat(auto-fill, 75px);
            gap: 25px;
            padding: 1rem 1rem 1rem 0;
            
            .shopping-cart-item {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                gap: 10px;
    
                .shopping-cart-item-photo {
                    width: 19%;
    
                    img {
                        width: 100%;
                    }
                }
    
                .shopping-cart-item-content {
                    width: 75%;
                    height: 100%;
    
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
    
                    .product-content-item {
                        // display: block;
    
                        &.product-name {
                            font-size: 14px;
                            font-weight: 500;
                            width: 100%;
    
                            .size-name {
                                font-weight: 700;
                            }
                        }
    
                        &.product-desc {
                            font-weight: 700;
                            font-size: 14px;
                            width: 100%;

                            .product-price {
                                &.discounted {
                                    font-size: 12px;
                                    font-weight: 500;
                                    text-decoration: line-through $baseDangerColor;
                                    -webkit-text-decoration-line: line-through;
                                    -webkit-text-decoration-color: $baseDangerColor;
                                }
                            }
                        }
                    }
                }

                .shopping-cart-remove-btn-container {
                    align-self: center;
                    width: 6%;

                    .btn-remove-cart-item {
                        background-color: $baseDangerColor;
                        color: $baseLightColor2;
                        box-shadow: -3px 4px 30px 1px rgba(#F34C39, 0.31);

                        width: 40px;
                        height: 40px;

                        border: none;
                        outline: none;

                        border-radius: 7px;
                    }
                }
            }
        }

        .checkout-container {
            height: 25%;

            display: flex;
            flex-direction: column;
            justify-content: space-between;
            // gap: 20px;

            padding: 1rem 0;

            border-top: 1px solid $baseGrayColor;

            .total-price-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-weight: 700;

                .desc-text {
                    text-transform: uppercase;
                }

                .total-price {

                }
            }

            .btn-container {

                display: flex;
                justify-content: space-around;
                
                button {
                    width: 48%;
                }
            }
        }
    }

    @media (max-width: 768px) {
        min-width: 0;
        height: 100%;
        padding: 1rem 1rem 0;
        
        &.offcanvas-end {
            width: 100%;
        }

        .shopping-cart-body {
            padding-left: 0;
            padding-right: 0;

            .shopping-cart-list {
                max-height: 60%;
                grid-template-rows: none;
                padding-right: 0;

                .shopping-cart-item {
                    .shopping-cart-item-photo {
                        width: 80px;
                    }

                    .shopping-cart-item-content {
                        // width: 58%;
                    }

                    .shopping-cart-remove-btn-container {
                        width: 10%;
                    }
                }
            }

            .checkout-container {
                gap: 1rem;
                margin-bottom: 1rem;
                height: fit-content;

                .btn-container {
                    width: 100%;
                    flex-direction: column;
                    gap: 1rem;

                    .btn-view-cart,
                    .btn-checkout {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.remove-cart-toast {
    display: grid;

    grid-template-areas: 'image content content'
                         'button button button';
    gap: 20px;

    .image {
        grid-area: image;

        img {
            width: 100%;
        }
    }

    .content {
        grid-area: content;

        .highlighted {
            font-weight: 600;
        }
    }
}