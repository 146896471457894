$baseDarkColor: var(--base-dark-color);
$baseDarkColor2: var(--base-dark-color-2);

$baseGrayColor: var(--base-gray-color);
$baseGrayColor3: var(--base-gray-color-3);

$baseLightColor: var(--base-light-color);
$baseLightColor2: var(--base-light-color-2);

$baseDangerColor: var(--base-danger-color);

.profile-page {
    // padding-top: 23vh;
    padding-bottom: 50px;
    max-width: 900px;
    min-height: 700px;
    margin: 0 auto;

    position: relative;

    display: flex;
    // align-items: center;
    justify-content: center;
    gap: 4rem;

    .profile-menu {
        min-width: 200px;
        width: 30%;
        padding-top: 28vh;

        display: flex;
        flex-direction: column;
        gap: 1rem;

        .profile-menu-title {
            font-weight: 700;
            font-size: 1.5rem;
        }

        .profile-menu-divisor {
            height: 3px;
            background-color: $baseDarkColor;
            width: 100%;
        }

        .profile-menu-list {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            .profile-menu-item {
                font-size: 0.9rem;
                color: $baseGrayColor;
                text-decoration: none;
                text-transform: uppercase;
                font-weight: 600;

                &:hover,
                &:focus,
                &.active {
                    color: $baseDarkColor;
                }
            }
        }
    }

    .profile-content {
        width: 60%;
        padding-top: 28vh;
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
        width: 100%;

        .profile-content-title {
            font-size: 1.5rem;
            line-height: 1.1;
        }

        .profile-content-divisor {
            height: 2.4px;
            background-color: $baseDarkColor;
            width: 100%;
        }
    }

    @media (max-width: 990px) {
        flex-direction: column;
        padding: 0 10%;
        gap: 2rem;

        .profile-menu {
            width: 100%;
            min-width: 0;
            padding-top: 175px;
            
            .profile-menu-list {
                flex-direction: row;
                gap: 1.5rem;
                overflow: auto;
            }
        }

        .profile-content {
            padding-top: 0;
            margin-bottom: 60px;
        }
    }
}

.customer-account-detail {
    .account-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;

        width: 70%;
        min-width: 400px;

        .account-label {
            color: $baseGrayColor;
            font-weight: 500;
        }

        .account-data {
            font-weight: 500;
            max-width: 200px;
            width: 100%;
        }

        @media (max-width: 990px) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .btn-account-modify-container {
        margin-top: 45px;

        .btn-account-modify {
            padding: 0.7rem 2rem;
        }
    }
}

.form-account-modify {
    .btn-account-modify {
        width: 100%;
    }
}

.profile-address-list {
    width: 100%;

    @media (max-width: 990px) {
        min-width: 0;
        padding-left: 0;
        padding-right: 0;
    }
}



.order-container {
    width: 100%;

    .order-status {
        overflow-x: auto;
        overflow-y: hidden;
        height: 80px;

        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        .order-status-item {
            height: 100%;
            padding: 0.5rem 1rem;

            background-color: rgba(0, 0, 0, 0);
            color: $baseGrayColor;
            font-weight: 500;

            border: none;
            outline: none;
            border-bottom: 1px solid $baseGrayColor3;

            &:hover, &:focus, &:active,
            &.active {
                color: $baseDarkColor;
                border-bottom: 2px solid $baseDarkColor;
                font-weight: 700;
            }
        }

        @media (max-width: 599px) {
            .order-status-item {
                font-size: 0.8rem;
            }
        }
    }

    .order-list {
        padding: 1rem 0;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: stretch;
        gap: 1rem;

        .order-item {
            width: 100%;
            // border: 2px solid $baseGrayColor3;
            border-radius: 10px;
            padding: 0 1.5rem;

            background-color: #FFF;

            .order-item-status {
                padding: 1rem 0;
                border-bottom: 1px solid $baseGrayColor3;

                display: flex;
                align-items: center;
                justify-content: space-between;

                .order-status-label {
                    font-weight: 500;
                }

                .order-status-value {
                    color: $baseDangerColor;
                    font-weight: 700;
                    text-align: right;

                    &.done {
                        color: $baseDarkColor;
                    }
                }

                @media (max-width: 599px) {
                    font-size: 0.85rem;
                    gap: 0.5rem;
                }
            }

            .order-item-info {
                padding: 1rem 0;

                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 2rem;

                border-bottom: 1px solid $baseGrayColor3;

                .product-image-container {
                    min-width: 80px;
                    max-width: 80px;
                    border-radius: 10px;
                    overflow: hidden;

                    img {
                        width: 100%;
                    }

                    @media (max-width: 440px) {
                        min-width: 60px;
                    }
                }

                .product-info-container {
                    min-height: 80px;

                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: space-evenly;

                    .product-name {
                        font-weight: 500;
                    }
                    
                    .product-attr {
                        display: flex;
                        gap: 2rem;

                        color: $baseGrayColor;
                        font-weight: 500;

                    }

                    @media (max-width: 599px) {
                        .product-name {
                            font-size: 0.9rem;
                        }
                        
                        .product-attr {
                            gap: 1rem;
                            font-size: 0.8rem;
                        }
                    }

                    @media (max-width: 440px) {   
                        .product-attr {
                            flex-direction: column;
                            gap: 0;
                            padding: 0.5rem 0;
                        }
                    }
                }
            }

            .order-item-total {
                color: $baseDarkColor;
                font-weight: 500;
                border-bottom: 1px solid $baseGrayColor3;

                padding: 1rem 0;

                display: flex;
                align-items: center;
                justify-content: space-between;

                .total-price {
                    .price-highlight {
                        font-weight: 700;
                    }
                }

                @media (max-width: 599px) {
                    font-size: 0.85rem;
                }

                @media (max-width: 440px) {
                    flex-direction: column;
                    align-items: flex-start;
                }
            }

            .order-button-detail {
                display: flex;
                align-items: center;
                justify-content: center;

                padding: 1rem 0 1rem;

                // cursor: pointer;

                width: 100%;
                font-weight: 700;

                text-decoration: none;
                color: $baseDarkColor;
            }
        }
    }

    .order-pagination {
        float: right;
        overflow: hidden;
        border-radius: 10px;

        
        .btn-pagination {
            border: none;
            outline: none;
            
            padding: 7px;
            
            background-color: $baseDarkColor;
            color: $baseLightColor2;
            font-weight: 600;

            &.btn-number {
                width: 40px;
            }

            &:hover,
            &:active,
            &:focus,
            &.active {
                background-color: $baseLightColor;
                color: $baseDarkColor2;
            }

            &.disabled {
                opacity: 0.8;

                &:hover,
                &:active,
                &:focus,
                &.active {
                    background-color: $baseDarkColor;
                    color: $baseLightColor2;
                    cursor: initial;
                }
            }
        }
    }
}