$textColor: var(--base-dark-color);
$backgroundColor: var(--base-primary-color);

$baseDarkColor1: var(--base-dark-color);
$baseDarkColor2: var(--base-dark-color-2);
$baseDarkColor3: var(--base-dark-color-3);

$baseLightColor: var(--base-light-color);
$baseLightColor2: var(--base-light-color-2);

.delivery-service-backdrop {
    z-index: 1056;
}

.delivery-service-modal {
    padding-top: 5vh;
    padding-bottom: 5vh;
    z-index: 1057;

    .modal-dialog {
        max-width: 70%;
        // height: 85%;

        .modal-content {
            height: 100%;

            .modal-body {
                padding: 2rem 1rem 2rem;

                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-evenly;
                row-gap: 2rem;

                .delivery-service-choices-container {
                    .delivery-service-choice-container {
                        .form-check-input:checked, 
                        .form-check-input:active, 
                        .form-check-input:focus {
                            background-color: $baseDarkColor3;
                            border: none;
                            outline: none;
                        }

                        .form-check-label {
                            font-size: 17px;
                        }
                    }
                }

                .delivery-service-calculation-info {
                    .info-container {
                        .service-name {

                        }

                        .service-costs {
                            .service-cost-item {
                                margin-bottom: 10px;

                                .service-cost-radio-btn-container {
                                    .form-check-input:checked, 
                                    .form-check-input:active, 
                                    .form-check-input:focus {
                                        background-color: $baseDarkColor3;
                                        border: none;
                                        outline: none;
                                    }
                                }
                            }
                        }
                    }
                }

                .delivery-service-confirm-btn-container {
                    width: 100%;

                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    .btn-confirm {
                        
                    }
                }
            }
        }

        @media (max-width: 990px) {
            .modal-content {
                .modal-body {
                    overflow: auto;

                    // & > * {
                    //     margin-top: 40vh;
                    // }
                }
                
            }
        }

        @media (max-width: 576px) {
            height: 50%;

            .modal-content {
                .modal-body {
                    overflow: auto;

                    // & > * {
                    //     margin-top: 30vh;
                    // }
                }
                
            }
        }

        @media screen and (max-width: 900px){
            max-width: 100%;
        }
    }
}