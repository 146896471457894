.group-items {
    width: 100%;
    margin: 0;
    padding: 0;

    .group-item-name {
        display: flex;
        align-items: center;
        justify-content: space-between;

        width: 100%;
        padding: 0.7rem 0;

        border: none;
        outline: none;
        
        background-color: rgba(0, 0, 0, 0);
        color: initial;

        cursor: pointer;

        .group-item-name {
            margin: 0;
            font-size: 16px;
        }

        .group-item-icon {
            font-size: 14px;
        }

    }

    .group-products {
        .group-product-item {
            display: block;
            text-decoration: none;
            color: initial;
            padding: 0.5rem 0 0.5rem 1rem;
            width: 100%;

            text-align: left;

            border: none;
            outline: none;
            
            background-color: rgba(0, 0, 0, 0);

            transition: all 0.1s ease;

            &:hover {
                opacity: 0.8;
            }
        }
    }

}