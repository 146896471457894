.header {
    width: 100%;
    // padding-top: 30vh;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    // height: 30vw;

    overflow: hidden;
    
    .header-container {
        width: 100%;
        overflow: hidden;
        
        .header-item-holder {
            width: 100%;
            max-height: 550px;
            overflow: hidden;   
            
            display: flex;
            align-items: stretch;
            justify-content: center;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;

                // background: red;

                width: 100%;
                height: 100%;

                // background-color: rgba(0, 0, 0, 0.5);

                // box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.75) inset;
                // -webkit-box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.75) inset;
                // -moz-box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.75) inset;

                z-index: 100;
            }

            .clickable-banner {
                z-index: 999;
            }

            .header-item {
                overflow: hidden;
        
                max-width: 100%;
                max-height: 100%;

                position: relative;
            }
        }
    }

    @media (max-width: 815px) {
        padding-top: 80px;
        height: fit-content;
        margin-bottom: 0;
    }
}