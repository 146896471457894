$backgroundColor: var(--base-primary-color);
$borderColor: var(--base-secondary-color);
$textColor: var(--base-dark-color);
$brownColor: var(--base-brown-color);

.article-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    gap: 2rem;

    padding: 150px 2rem 2rem;

    background-color: $backgroundColor;

    .article-page-title {
        border-bottom: 5px solid $brownColor;
        font-weight: 700;
        color: $textColor;
    }
}