$basePrimaryColor: var(--base-primary-color);
$baseSecondaryColor: var(--base-secondary-color);

.storefront-banner-container {
    // width: 100%;
    margin-bottom: 0.6rem;

    position: relative;

    .storefront-content {
        position: absolute;
        z-index: 1000;

        width: 100%;
        height: 100%;

        .storefront-title {
            font-size: 2.5em;
            
            background-color: rgba(0, 0, 0, 0.5);
            color: $basePrimaryColor;

            width: fit-content;
            padding: 0.5rem;

            text-transform: uppercase;
        }

        .storefront-button {
            text-decoration: none;
            outline: 5px solid rgba(255, 255, 255, 0.5);
            border-radius: 2px;

            color: $basePrimaryColor;
            background-color: $baseSecondaryColor;

            font-size: 25px;
            font-weight: 600;
            text-transform: uppercase;
            padding: 0.8rem 1rem;

            position: absolute;
            right: 40px;
            bottom: 25px;
        }
    }

    .storefront-banner {
        width: 100%;
    }
}

@media (max-width: 1150px) {
    .storefront-banner-container {
        .storefront-content {
            .storefront-title {
                font-size: 1.5rem;
            }

            .storefront-button {
                font-size: 16px;
            }
        }
    }
}