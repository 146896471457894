.quick-peek-modal {
    margin-top: 5vh;
    margin-bottom: 5vh;

    .modal-dialog {
        max-width: 60%;
        height: 85%;

        .modal-content {
            height: 100%;

            .modal-body {
                padding: 1rem 0.5rem 3rem;

                display: flex;
                align-items: center;
                justify-content: stretch;

                .product-detail {
                    width: 100%;
                }
            }
        }

        @media (max-width: 990px) {
            height: fit-content;
            .modal-content {

                .modal-body {
                    // overflow: auto;
                    // padding-top: 0;

                    & > * {
                        // padding-top: 200px;
                    }
                }
                
            }
        }

        @media (max-width: 576px) {
            .modal-content {
                .modal-body {

                    // & > * {
                    //     margin-top: 30vh;
                    // }
                }
                
            }
        }

        @media screen and (max-width: 900px){
            max-width: 90%;
        }
    }

    @media (max-width: 990px) {
        margin: 0;
    }
}