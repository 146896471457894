.search-bar {
    width: 250px;
    position: relative;
    
    .search-icon {
        position: absolute;
        z-index: 999;
        left: 17px;
        top: 7px;
        width: 12px;
    }
    
    .search-box {
        width: 100%;
        background-color: #E9ECEF;
        padding: 6px 12px 6px 38px;
        border: none;
        outline: none;
        font-size: 12px;
        border-radius: 41px;
    }
}