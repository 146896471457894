* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* color: var(--primary-text-color); */
  font-family: 'Laro';
}

.App {
  background-color: var(--base-light-color-2);
  color: var(--primary-text-color);
  position: relative;
  min-height: 100vh;
  height: fit-content;
}

.App.dark-mode {
  background-color: #222222;
  color: #FFF;
}

.App .hide {
  visibility: hidden;
  opacity: 0;
}

.base-bg-dark {
  background-color: var(--base-bg-dark);
}

h1, h2, h3, h4, h5, h6 {
  /* font-family: Helvetica; */
}

/* Fonts */

@font-face {
  font-family: 'Inter';
  src: url('./Fonts/Inter/Inter-VariableFont_slnt,wght.ttf');
}

/* Static 'Inter' Font */

@font-face {
  font-family: 'Inter';
  src: url('./Fonts/Inter/static/Inter-Thin.ttf');
  font-weight: 100;
}

@font-face {
  font-family: 'Inter';
  src: url('./Fonts/Inter/static/Inter-ExtraLight.ttf');
  font-weight: 200;
}

@font-face {
  font-family: 'Inter';
  src: url('./Fonts/Inter/static/Inter-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'Inter';
  src: url('./Fonts/Inter/static/Inter-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Inter';
  src: url('./Fonts/Inter/static/Inter-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Inter';
  src: url('./Fonts/Inter/static/Inter-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'Inter';
  src: url('./Fonts/Inter/static/Inter-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Inter';
  src: url('./Fonts/Inter/static/Inter-ExtraBold.ttf');
  font-weight: 800;
}

@font-face {
  font-family: 'Inter';
  src: url('./Fonts/Inter/static/Inter-Black.ttf');
  font-weight: 900;
}

/* Static 'Laro' Font */

@font-face {
  font-family: 'Laro';
  src: url('./Fonts/Laro/Laro-Thin.ttf');
  font-weight: 100;
}

@font-face {
  font-family: 'Laro';
  src: url('./Fonts/Laro/Laro-ThinItalic.ttf');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Laro';
  src: url('./Fonts/Laro/Laro-ExtraLight.ttf');
  font-weight: 200;
}

@font-face {
  font-family: 'Laro';
  src: url('./Fonts/Laro/Laro-ExtraLightItalic.ttf');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Laro';
  src: url('./Fonts/Laro/Laro-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'Laro';
  src: url('./Fonts/Laro/Laro-LightItalic.ttf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Laro';
  src: url('./Fonts/Laro/Laro-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Laro';
  src: url('./Fonts/Laro/Laro-RegularItalic.ttf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Laro';
  src: url('./Fonts/Laro/Laro-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Laro';
  src: url('./Fonts/Laro/Laro-MediumItalic.ttf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Laro';
  src: url('./Fonts/Laro/Laro-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'Laro';
  src: url('./Fonts/Laro/Laro-SemiBoldItalic.ttf');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Laro';
  src: url('./Fonts/Laro/Laro-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Laro';
  src: url('./Fonts/Laro/Laro-BoldItalic.ttf');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Laro';
  src: url('./Fonts/Laro/Laro-ExtraBold.ttf');
  font-weight: 800;
}

@font-face {
  font-family: 'Laro';
  src: url('./Fonts/Laro/Laro-ExtraBoldItalic.ttf');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Laro';
  src: url('./Fonts/Laro/Laro-Black.ttf');
  font-weight: 900;
}

@font-face {
  font-family: 'Laro';
  src: url('./Fonts/Laro/Laro-BlackItalic.ttf');
  font-weight: 900;
  font-style: italic;
}

/* Static 'PTSerif' Font */

@font-face {
  font-family: 'PTSerif';
  src: url('./Fonts/PTSerif/PTSerif-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'PTSerif';
  src: url('./Fonts/PTSerif/PTSerif-Italic.ttf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'PTSerif';
  src: url('./Fonts/PTSerif/PTSerif-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'PTSerif';
  src: url('./Fonts/PTSerif/PTSerif-BoldItalic.ttf');
  font-weight: 700;
  font-style: italic;
}