.size-chart-backdrop {
    z-index: 1056;
}

.size-chart-modal {
    padding-top: 5vh;
    padding-bottom: 5vh;
    z-index: 1057;

    .modal-dialog {
        max-width: 70%;
        // height: 85%;

        .modal-content {
            height: 100%;

            .modal-body {
                padding: 2rem 1rem 4rem;

                display: flex;
                align-items: center;
                justify-content: stretch;

                .chart-carousel-container {
                    width: 100%;

                    .chart-holder {
                        width: 100%;
                        text-align: center;

                        img {
                            width: 100%;
                            max-width: 600px;

                            @media screen and (max-width: 900px){
                                width: 80%;
                            }

                            @media (max-width: 700px) {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }

        @media (max-width: 990px) {
            .modal-content {
                .modal-body {
                    overflow: auto;

                    // & > * {
                    //     margin-top: 40vh;
                    // }
                }
                
            }
        }

        @media (max-width: 576px) {
            height: 50%;

            .modal-content {
                .modal-body {
                    overflow: auto;

                    // & > * {
                    //     margin-top: 30vh;
                    // }
                }
                
            }
        }

        @media screen and (max-width: 900px){
            max-width: 100%;
        }
    }
}