$baseColor: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

$backgroundColor: var(--white-color);

.article-list-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    row-gap: 2rem;

    padding: 0 20vw;
    gap: 1.5rem;

    .article-list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 2rem;

        position: relative;
        min-width: 50%;
        min-height: 400px;

        .article-item {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-around;

            background-color: $backgroundColor;
            // border: 1px solid $baseColor;
            border-radius: 25px;
            
            transition: all 0.4s ease;

            padding: 1rem 1rem;

            &:hover {
                cursor: pointer;
                opacity: 0.7;
            }

            .article-thumbnail {
                min-width: 120px;
                width: 30%;

                img {
                    width: 100%;
                }
            }

            .article-content {
                width: 70%;
                padding: 0 2rem 1rem;

                .article-text {
                    margin-top: 15px;
                    // display: none;
                }
            }

            &.see-more {
                background-color: unset;
                border: unset;

                .btn-article-see-more {
                    // width: 100%;
                    text-align: center;
                    text-decoration: none;
    
                    background-color: $baseColor;
                    color: $baseColor4;
    
                    padding: 1rem 2rem;
                    border: 3px solid $baseColor;
                    border-radius: 40px;
                }

                &:hover {
                    opacity: 1;

                    .btn-article-see-more {
                        opacity: 0.7;
                        transition: all 0.4s ease;
                    }
                }
            }

        }
    }

    @media (max-width: 1600px) {
        padding: 0 10vw;
    }

    @media (max-width: 1200px) {
        padding: 0 5vw;
    }

    @media (max-width: 1000px) {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;    
    }

    @media (max-width: 768px) {
        padding: 0;

        .article-list {
            .article-item {
                .article-content {
                    padding: 0 0 0.5rem 1rem;
                }    
            }
        }
    }

    @media (max-width: 500px) {
        .article-list {
            .article-item {
                flex-direction: column;
                gap: 2rem;

                overflow: hidden;
                padding: 0;

                .article-thumbnail {
                    width: 100%;
                }

                .article-content {
                    width: 100%;
                    padding: 0 1rem 1rem;
                    
                    .content-title {
                        font-size: 1rem;
                    }
        
                    .content-publisher,
                    .content-publish-time {
                        font-size: 0.8rem;
        
                    }
        
                    .article-text {
                        font-size: 0.8rem;
                    }
                }    
            }
        }
    }

    .article-iframe {
        display: flex;
        align-items: center;
        justify-content: center;

        position: relative;

        width: 100%;
        min-height: 400px;
    }
}