$backgroundColor: var(--white-color);
$primaryTextColor: var(--primary-text-color);
$whiteColor: var(--base-primary-color);
$buttonColor: var(--base-brown-color);

.article-collection-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    row-gap: 2rem;

    padding: 0 20vw;
    gap: 1.5rem;

    .article-collection {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        position: relative;
        min-width: 50%;
        min-height: 400px;

        .article-item {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-around;

            // border: 1px solid $baseColor;
            border-radius: 25px;
            
            transition: all 0.4s ease;

            margin: 1rem 0;
            padding: 1rem 1rem;

            &:hover {
                cursor: pointer;
                opacity: 0.7;
            }

            .article-thumbnail {
                min-width: 120px;
                width: 30%;

                img {
                    width: 100%;
                }
            }

            .article-content {
                width: 70%;
                padding: 0 2rem 1rem;

                .article-text {
                    margin-top: 15px;
                }
            }

            &.see-more {
                background-color: unset;
                border: unset;

                .btn-article-see-more {
                    // width: 100%;
                    text-align: center;
                    text-decoration: none;
    
                    background-color: $whiteColor;
                    color: $primaryTextColor;
    
                    padding: 1rem 2rem;
                    border: 3px solid $buttonColor;
                    border-radius: 40px;
                }

                &:hover {
                    opacity: 1;

                    .btn-article-see-more {
                        opacity: 0.7;
                        transition: all 0.4s ease;
                    }
                }
            }

            &.article-search-container {
                background-color: unset;
                border: unset;
                color: $backgroundColor;

                cursor: initial;
                margin: 0;

                justify-content: flex-start;
                
                & > * {
                    width: 100%;
                }

                &:hover {
                    opacity: 1;
                }

                label {
                    font-size: 1.25rem;
                }

                input {
                    color: $buttonColor;
                    font-size: 1.1rem;
                }

                .search-button {
                    border: 0;
                    border-radius: 20px;
                    padding: 0.5rem 2rem;
                    margin-bottom: 1rem;

                    // font-size: 1.25rem;

                    background: $buttonColor;
                    color: $whiteColor;

                    width: fit-content;
                }
            }

            &.empty-page {
                flex-direction: column;
                align-items: center;
                justify-content: center;
                min-height: 400px;

                cursor: initial;
                
                &:hover {
                    opacity: 1;
                }

                .search-icon {
                    font-size: 40px;
                }

                .search-result {
                    font-size: 30px;
                }
            }

        }
    }

    @media (max-width: 1600px) {
        padding: 0 10vw;
    }

    @media (max-width: 1200px) {
        padding: 0 5vw;
    }

    @media (max-width: 1000px) {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;    
    }

    @media (max-width: 768px) {
        padding: 0;

        .article-collection {
            .article-item {
                .article-content {
                    padding: 0 0 0.5rem 1rem;
                }    
            }
        }
    }

    @media (max-width: 500px) {
        .article-collection {
            .article-item {
                flex-direction: column;
                gap: 2rem;

                overflow: hidden;
                padding: 0;

                .article-thumbnail {
                    width: 100%;
                }

                .article-content {
                    width: 100%;
                    padding: 0 1rem 1rem;
                    
                    .content-title {
                        font-size: 1rem;
                    }
        
                    .content-publisher,
                    .content-publish-time {
                        font-size: 0.8rem;
        
                    }
        
                    .article-text {
                        font-size: 0.8rem;
                    }
                }    
            }
        }
    }

    .article-iframe {
        display: flex;
        align-items: center;
        justify-content: center;

        position: relative;

        width: 100%;
        min-height: 400px;
    }
}