.payment-form-container {
    font-weight: 500;

    .payment-form-title {
        font-weight: 700;
    }

    .payment-form {
        .form-control,
        .form-select {
            font-size: 13px;
        }
    }

    .btn-payment-confirm-form {
        width: 100%;
    }
}