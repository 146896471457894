.product-detail {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.full-page {
        
        .product-content {

            .product-content-header {
        
                .product-name {
                    font-weight: 700;
                }
            }
        }
    }

    @media (max-width: 990px) {
        flex-direction: column;
        gap: 3rem;
    }
}