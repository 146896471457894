$textColor: var(--base-dark-color);
$backgroundColor: var(--base-primary-color);
$secondaryColor: var(--base-secondary-color);

$baseDarkColor1: var(--base-dark-color);
$baseDarkColor2: var(--base-dark-color-2);
$baseDarkColor3: var(--base-dark-color-3);

$baseGrayColor3: var(--base-gray-color-3);
$baseGrayColor4: var(--base-gray-color-4);

$baseLightColor: var(--base-light-color);
$baseLightColor2: var(--base-light-color-2);

$marqueeBackground: var(--marquee-gradient);

.navbar {
    font-family: 'Helvetica';
    padding: 8px 0 0;
    background-color: $backgroundColor;
    box-shadow: 2px 2px 5px -5px $textColor;
    position: fixed;
    width: 100%;
    z-index: 1001;

    filter: drop-shadow(0px 8px 20px rgba(173, 181, 189, 0.4));

    flex-direction: column;
    
    .navbar-first-row {
        width: 100%;

        padding: 0.1rem 2.5vw 0.6rem;

        display: flex;
        justify-content: space-between;
        align-items: center;

        
    
        .navbar-brand {
            // height: 70px;
            
            a {
                vertical-align: middle;
                
                @media (max-width: 768px) {
                    
                }
            }

            .nav-logo {
                width: 100px;
            }
        }

        .mobile-sidebar-toggle {
            font-size: 20px;

            background-color: rgba(0, 0, 0, 0);
            color: $textColor;
            border: none;
            outline: none;

            position: relative;
        }

        // position: relative;

        .nav-container {
            // width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1rem;

            position: relative;
            
            .brand-name {
                font-weight: 700;
                text-transform: uppercase;
                font-size: 13px;
                color: $textColor;
                text-decoration: none;
                cursor: pointer;
                border-bottom: 3px solid rgba(0, 0, 0, 0);
                margin: 0;
                padding: 8px 0;
    
                &:active, 
                &:hover, 
                &:focus,
                &.active {
                    // font-weight: 600;
                    border-bottom: 3px solid $textColor;
                }
            }
    
            .navbar-hover {
                width: 90vw;
                // max-width: 1000px;
                min-height: 200px;
        
                z-index: 1010;
                position: absolute;
                top: 40px;
                left: 0;
                padding: 3rem 3rem;
                display: none;
    
                &.show {
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    flex-wrap: wrap;

                    overflow-y: auto;
                    
                    column-gap: 0.8rem;
                    row-gap: 2rem;
                }
    
                background-color: $baseGrayColor4;

                .group-container {
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;

                    flex-direction: column;

                    // width: 100%;
                    min-width: 150px;
                    
                    .group-name {
                        width: 100%;
                        // text-align: center;
        
                        font-size: 15px;
                        font-weight: 700;
                        text-transform: uppercase;

                        margin-bottom: 20px;
                    }
        
                    .group-items {
                        width: 100%;
        
                        display: grid;
                        grid-template-columns: 200px;
                        column-gap: 50px;
                        row-gap: 10px;
                        
                        .group-item {
                            text-decoration: none;
                            color: $textColor;

                            font-size: 12px;
                            font-weight: 500;

                            width: fit-content;
                            
                            text-transform: uppercase;
        
                            &:active, 
                            &:hover, 
                            &:focus,
                            &.active {
                                font-weight: 600;
                            }
                        }
                    }
                }
    
    
            }
        }


        @media (max-width: 768px) {
            .nav-container {
                display: none;
            }

            .nav-logo {
                width: 120px;
            }
        }
    }

    .navbar-second-row {
        width: 100%;
        // background-color: $secondaryColor;
        background: $secondaryColor;
        background: $marqueeBackground;
        color: $baseLightColor2;
        font-size: 13px;

        font-style: italic;
        text-transform: uppercase;
        font-weight: 600;

        padding: 0.3rem 0;

        @media (max-width: 420px) {
            font-size: 0.8rem;
        }
    }
}