$titleLineColor: var(--base-primary-color-2);

$baseColor1: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);

$baseColorShade: var(--base-light-color);

$backgroundColor: var(--white-color);
$primaryTextColor: var(--primary-text-color);

.question-item {
    border-bottom: 1px solid $baseColorShade;
    cursor: pointer;
    overflow: hidden;

    .question-box {
        background-color: $baseColor1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        .accordion-button {
            font-weight: 600;

            &:not(.collapsed) {
                color: $baseColor2;
                background-color: $baseColorShade;
                box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 $backgroundColor;
                
                &::after {
                    background-image: var(--bs-accordion-btn-icon);
                }
            }

            &:hover {
                background-color: $baseColorShade;
                opacity: 0.8;
            }

        }


        .question-title {
            width: 80%;
        }
    }

    .question-content {
        background-color: $baseColorShade;

        .list-title {
            font-weight: 600;
        }

        a {
            color: inherit;
            transition: all 0.3s ease;

            &:hover,
            &:focus,
            &:active {
                color: $baseColor1;
            }
        }
    }
}