$secondaryTextColor: var(--base-dark-color-3);
$buttonCarouselColor: var(--base-gray-color);
$buttonSeeMore: var(--base-dark-color);

$baseLightColor: var(--base-light-color);
$baseDangerColor: var(--base-danger-color);

.product-card-container {
    position: relative;
    margin: 1rem;

    .btn-quick-look {
        width: 2%;
        min-width: 30px;
        position: absolute;
        z-index: 1000;

        top: 5px;
        right: 5px;

        cursor: pointer;
    }

    .product-card {
        height: 100%;
        border: none;
        // border-radius: 6px;
        // box-shadow: 0px 11px 20px 2px rgba(0, 54, 165, 0.1);
        // overflow: hidden;
        transition: 0.1s all;
    
        background-color: rgba(0, 0, 0, 0);
        color: inherit;
        text-decoration: none;
    
        cursor: pointer;

        display: flex;
        flex-direction: row;
        gap: 30px;
    
        .product-card-body {
            padding-left: 0;
            padding-right: 0;
    
            .product-card-name {
                font-weight: 700;
                font-size: 15px;
                text-transform: uppercase;
                // min-height: 40px;
            }
    
            .product-card-desc {
                .product-card-first-row {
                    display: flex;
                    align-items: center;
                    text-transform: uppercase;
                    // justify-content: space-between;
    
                    .product-card-category {
                        font-size: 11px;
                        color: $secondaryTextColor;
                    }
    
                    .product-card-stock {
                        font-size: 9px;
                        color: $secondaryTextColor;
                    }
                }
    
                .product-card-price {
                    font-size: 14px;
                    font-weight: 600;
    
                    &.discounted {
                        font-size: 12px;
                        text-decoration: line-through $baseDangerColor;
                        -webkit-text-decoration-line: line-through;
                        -webkit-text-decoration-color: $baseDangerColor;
                    }
                }
            }
        }
    
        &-image {
            width: 20%;
            box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.1);
            // border-radius: 6px;
        }
    }
}