$lightColor: var(--base-light-color);

.linktree-page {
    position:relative;
    width: 100%;
    min-height: 100vh;
    // height: 100%;
    
    display: flex;
    align-items: center;
    justify-content: center;

    background-image: var(--linktree-background);
    background-position: center;
    background-size: cover;

    padding: 4rem 0;

    .linktree-container {
        width: 57%;
        min-width: 500px;
        background-color: var(--base-bg-secondary);

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        background-color: rgba(0, 0, 0, 0.6);
        color: $lightColor;
        border-radius: 30px;

        .linktree-logo {
            max-width: 200px;
            width: 80%;

            img {
                width: 100%;
            }
        }

        .linktree-header {
            .linktree-title {
                font-size: 2rem;
                font-weight: 500;
            }
        }

        .linktree-tagline {
            font-family: 'PTSerif';
            font-style: italic;
            text-transform: uppercase;
            text-align: center;

            width: 300px;
            font-size: 18px;
            opacity: 0.8;

        }

        .linktree-list {
            width: 90%;
            margin-top: 2rem;
            margin-bottom: 2rem;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 1rem;

            .linktree-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                text-decoration: none;

                background: linear-gradient(120deg, black 0%, #353434 70%);
                color: $lightColor;

                width: 100%;
                padding: 0.5rem;
                border-radius: 10px;

                // box-shadow: 1px 1px 8px 1px #000;
    
                .linktree-item-icon {
                    width: 50px;
                    border-radius: 10px;
                    overflow: hidden;
    
                    img {
                        width: 100%;
                    }
                }

                .linktree-item-caption {

                }
    
                .linktree-item-logo {
                    width: 50px;
    
                    img {
                        width: 100%;
                        transform: rotate(90deg);
                    }
                }
            }
        }

        @media screen and (max-width: 550px) {
            min-width: 0;
            width: 90%;

            .linktree-header {
                .linktree-title {
                    font-size: 25px;
                }
            }

            .linktree-tagline {
                width: 70%;
                font-size: 16px;
            }

            .linktree-list {
                .linktree-item {
                    .linktree-item-icon {
                        width: 40px;
                    }

                    .linktree-item-caption {
                        font-size: 13px;
                    }

                    .linktree-item-logo {
                        width: 30px;
                    }
                }
            }
        }
    }

}