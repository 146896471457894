.product-list-container {
    position: relative;
    
    .product-list-container-header {
        display: flex;
        justify-content: space-between;

        .product-list-name {
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 1rem;
            font-size: 1.5rem;

        }

        @media (max-width: 768px) {
            margin-bottom: 3.5rem;

            .product-list-name {
                margin-bottom: 0;
            }
        }

        @media (max-width: 500px) {
            .product-list-name {
                font-size: 1.3rem;
            }
        }

        @media (max-width: 330px) {
            .product-list-name {
                font-size: 1rem;
            }
        }
    }
}