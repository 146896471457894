.address-form {
    min-width: 700px;
    width: 65%;

    padding-left: 12%;
    padding-right: 12%;
    margin-bottom: 30px;

    .address-form-title {
        font-size: 1.6rem;
        font-weight: 500;
        margin-bottom: 30px;
    }

    @media screen and (max-width: 1035px) {
        width: 100%;
    }
}