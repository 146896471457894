$baseDarkColor: var(--base-dark-color);
$baseLightColor2: var(--base-light-color-2);

.order-success-message {
    min-height: 300px;
    padding: 1rem 0;
    
    background-color: $baseDarkColor;
    color: $baseLightColor2;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;

    &-title {
        font-weight: 400;
        text-align: center;
        width: 80%;
    }

    &-text {
        text-align: center;
        width: 50%;
    }

    .order-confirm-btn {
        border-color: $baseLightColor2;
        width: 80%;
        max-width: 300px;
        font-size: 16px;
    }

    @media (max-width: 768px) {
        &-title {

        }

        &-text {
            width: 80%;
        }
    }
}