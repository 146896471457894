$baseSecondaryColor: var(--base-secondary-color);

.catalog-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: 0 15px;
    padding-bottom: 10px;

    border-bottom: 1px solid $baseSecondaryColor;

    .view-options {
        width: 100%;

        .catalog-icon {
            width: 35px;
            opacity: 0.7;
            cursor: pointer;

            &.active {
                opacity: 1;
            }
        }
    }

    .sort-dropdown-container {
        width: 100%;
        
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;

        .sort-dropdown-label {
            font-weight: 500;
        }

        .sort-dropdown {
            font-weight: 500;
            width: 300px;
        }
    }
}