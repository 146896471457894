$baseDarkColor: var(--base-dark-color);
$baseGrayColor: var(--base-gray-color);

$baseLightColor: var(--base-light-color);
$baseLightColor2: var(--base-light-color-2);

.checkout-section {
    width: 30%;
    // height: 500px;
    min-width: 400px;

    background-color: $baseLightColor2;
    // color: white;
    border: 2px solid black;

    padding: 1.5rem 1rem;

    filter: drop-shadow(0px 8px 20px rgba(173, 181, 189, 0.4));
    border-radius: 6px 6px 0px 0px;


    .checkout-section-title {
        font-weight: 600;
        font-size: 1.5rem;
    }

    .checkout-detail {
        width: 100%;
        height: 100%;

        margin: 2rem 0;

        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: space-between;

        .checkout-detail-table {
            width: 100%;

            tbody {
                tr {
                    td {
                        padding: 0.5rem 0;
                        font-size: 14px;
                    }
                }
            }

            tfoot {
                tr {
                    
                    font-weight: 600;
                    
                    td {
                        padding: 1rem 0;
                        font-size: 14px;
                    }

                    &.total-row {
                        border-top: 1px solid $baseGrayColor;
                        border-bottom: 1px solid $baseGrayColor;
                        font-size: 15px;
                    }

                    &.payment-method-desc {
                        font-weight: 400;

                        td {
                            padding: 0 0.5rem 0 0;
                            font-size: 13px;

                            img {
                                width: 100%;
                            }

                            .bank-account {
                                // font-style: italic;
                                // cursor: pointer;
                                font-size: 0.9rem;
                                text-decoration: 2px underline;
                            }
                        }
                    }
                }
            }
        }
    }

    .checkout-button {
        width: 100%;
        font-size: 15px;
        padding: 10px 10px;
    }

    @media screen and (max-width: 1275px) {
        min-width: 330px;

        .checkout-section-title {
            
        }
    
        .checkout-detail {
            .checkout-detail-table {
                tbody {
                    tr {
                        td {
                            padding: 0.3rem 0;
                        }
                    }
                }
    
                tfoot {
                    tr {
                        td {
                            // padding: 0.5rem 0;
                        }
    
                        &.payment-method-desc {
                            td {
                                padding: 0 0.3rem 0 0;
                            }
                        }
                    }
                }
            }
        }
    
        .checkout-button {

        }
    }

    @media screen and (max-width: 1035px) {
        width: 100%;

        .checkout-detail {
            .checkout-detail-table {
                tfoot {
                    tr {
                        &.payment-method-desc {
                            td {
                                img {
                                    width: 80%;
                                    max-width: 600px;
                                    margin: 0 20px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        min-width: 0;
    }

    // @media (max-width: 600px) {
        
    // }
}