$baseLightColor2: var(--base-light-color-2);
$baseDarkColor: var(--base-dark-color);
$baseGrayColor: var(--base-gray-color);
$baseGrayColor3: var(--base-gray-color-3);

.checkout-page {
    // background-color: $baseLightColor2;

    padding: 2rem;
    padding-top: 180px;

    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;

    #section-1 {

    }

    #section-2 {
        
    }

    #section-3 {
        width: 100%;
    }

    .checkout-address-list {
        min-width: 500px;
        width: 65%;
    }
    
    @media screen and (max-width: 1035px) {
        flex-direction: column-reverse;
        gap: 2rem;

        .checkout-address-list {
            width: 100%;
        }
    }

    @media screen and (max-width: 768px) {
        .checkout-address-list {
            min-width: 0;
            &.address-form {
                padding: 0 1rem;
            }
        }
    }
}

