$baseGrayColor: var(--base-gray-color);
$baseGrayColor3: var(--base-gray-color-3);

$baseLightColor: var(--base-light-color);
$baseLightColor2: var(--base-light-color-2);

$baseDarkColor: var(--base-dark-color);
$baseDarkColor2: var(--base-dark-color-2);
$baseDarkColor3: var(--base-dark-color-3);

.textbox-container {
    border-radius: 3px;
    overflow: hidden;
    padding: 0;
    margin: 0;
    border: 2px solid $baseGrayColor3;
    background-color: $baseLightColor;
    
    width: 140px;
    height: 45px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    
    .btn-toggle {
        padding: 0.5rem;
        width: 45px;
        height: 45px;
        border: 0;
        // border: 1px solid $baseGrayColor3;
        outline: none;
        margin: 0;
        padding: 0;
        
        background-color: $baseLightColor;
        color: $baseDarkColor3;

        z-index: 1;
        
        font-size: 20px;

        &.btn-disabled {
            color: $baseGrayColor;
        }
    }
    
    .quantity-input {
        padding: 0.4rem;
        width: 55px;
        height: 45px;

        font-size: 20px;

        border: none;
        border-left: 2px solid $baseGrayColor3;
        border-right: 2px solid $baseGrayColor3;
        outline: none;
        margin: 0;
        
        text-align: center;
    }

    &.sm {
        width: 100px;
        height: 35px;

        .quantity-input {
            width: 40px;
            font-size: 1rem;
        }
    }
}