$baseColor1: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

.article-item-container {
    padding: 2rem 0;
    
    .article-item-holder {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        
        .article-item {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
    
            width: 80%;
            min-height: 650px;
            height: max-content;
    
            // gap: 1rem;
            
            background-color: $baseColor3;
            outline: 5px solid $baseColor2;
            border-radius: 20px;
            overflow: hidden;
    
            margin: 0 20px 0;
            position: relative;
    
            .article-thumbnail {
                width: 100%;
                // height: 250px;
                
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            
            .article-content {
                flex: 1;
                margin: 30px 20px 100px 30px;
                
                .title {
                    margin: 0;
                    font-size: 23px;
                    font-weight: bold;
                    color: $baseColor1;
                    // margin-bottom: 5px;
                }

                .publisher {
                    color: $baseColor1;
                }

                .publish-time {
                    font-size: 14px;
                }
    
                .desc {
                    margin-top: 16px;
                    font-size: 16px;
                    color: #000;
                }
            }

            .btn-article-see-more {
                position: absolute;
                right: 10px;
                bottom: 10px;
                padding: 10px 15px;
                
                border-radius: 25px;
                outline: none;
                border: 2px solid $baseColor1;
                cursor: pointer;
                
                text-decoration: none;
                background-color: $baseColor4;
                color: $baseColor1;
                
                transition: 0.4s;
            }
            
            .btn-article-see-more:hover {
                border-color: $baseColor2;
                color: $baseColor2;
            }
            
        }
        
        @media only screen and (min-width: 700px) {
            .article-item {
                flex-wrap: nowrap;
     
                .article-thumbnail {
                    // width: 300px;
                    // height: 310px;
                }
            }    
        
        }
    }

}