.about-us-page {
    position: relative;
    width: 100%;
    min-height: 100vh;

    padding-top: 180px;
    padding-bottom: 100px;

    display: flex;
    flex-direction: column;
    align-items: center;
    
    width: 100%;

    .about-us-container {
        width: 60%;
        min-width: 500px;
        
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
    
        h1, h2 {
            font-size: calc(1.375rem + 1.5vw);
        }

        .about-us-title {
            font-weight: 700;
            text-transform: uppercase;
            // font-family: 'Inter';
        }

        .about-us-content {
            text-align: center;
            line-height: 1.7rem;
        }

        .about-us-our-store {
            width: 100%;

            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            
            .our-store-container {
                width: 100%;
                overflow: hidden;
                
                .our-store-holder {
                    width: 100%;
                    max-height: 550px;
                    overflow: hidden;   
                    
                    display: flex;
                    align-items: stretch;
                    justify-content: center;

                    img {
                        overflow: hidden;
                
                        max-width: 100%;
                        max-height: 100%;

                        position: relative;
                    }
                }
                
            }
        }

        .about-us-company-profile {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            gap: 1rem;

            width: 100%;

            .company-name {
                font-size: 23px;
                font-weight: 500;
            }

            .company-content {
                width: 100%;

                .company-maps {
                    margin-bottom: 1rem;

                    .company-gmaps {
                        border: 0;
                        width: 100%;
                        height: 400px;
                    }
                }

                .company-info {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
    
                    gap: 1rem;
                }
            }
        }

        @media screen and (max-width: 560px) {
            min-width: 0;
            width: 80%;
        }
    }

}