$baseGrayColor: var(--base-gray-color);
$baseLightColor2: var(--base-light-color-2);
$baseDarkColor: var(--base-dark-color);
$baseDarkColor3: var(--base-dark-color-3);

$baseDangerColor: var(--base-danger-color);

.mobile-sidebar-container {
    padding: 1rem 0 0;
    // overflow: auto;
    // min-width: 550px;

    .mobile-sidebar-header {
        font-family: 'Helvetica';
        border-bottom: 1px solid $baseGrayColor;
        // width: 85%;
        padding: 0.5rem 1.5rem 1rem 1rem;

        .mobile-sidebar-title {
            font-size: 2rem;
            text-transform: uppercase;
            font-weight: 700;
            margin-bottom: 0;
        }

        .search-bar {
            // width: 300px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            
            .search-icon {
                width: 15px;
                color: #FFF;
                position: relative;
                left: initial;
                top: initial;
            }
            
            .search-box {
                width: 75%;
                background-color: #E9ECEF;
                padding: 10px 15px 10px 25px;
                border: none;
                outline: none;
                font-size: 14px;
                border-radius: 41px 0 0 41px;
            }

            .mobile-search-btn {
                box-shadow: none;
                border-radius: 0 41px 41px 0;
                height: 41px;
            }
        }

    }

    .mobile-sidebar-body {
        padding: 0.6rem 0 1rem;

        hr {
            opacity: 1;
            border-color: $baseGrayColor;
            margin: 0;
            margin-top: 1rem;
        }

        .body-section {
            width: 100%;
            
            .accordion-item {
                border-radius: 0;
                
                .accordion-header {
                    width: 100%;
                    border: 0;
                    outline: 0;
                    border-radius: 0;

                    border-bottom: 1px solid $baseGrayColor;
                    
                    .accordion-button {
                        // font-family: 'Inter';
                        font-weight: 500;

                        &:focus {
                            outline: 0;
                            border: 0;
                            box-shadow: none;
                        }
    
                        &:not(.collapsed) {
                            color: initial;
                            background-color: initial;
                            box-shadow: none;
        
                            &::after {
                                background-image: var(--bs-accordion-btn-icon);
                            }
                        }
                    }
                }

                .accordion-body {
                    display: flex;
                    flex-direction: column;
                    padding: 0;
                    margin: 0;

                    .sidebar-item {
                        padding-left: 2rem;
                    }
                }
            }

            &.auth-section {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                row-gap: 15px;

                padding: 0.5rem 0 1rem;

                .auth-username {
                    margin-left: 1.5rem;
                    font-weight: 600;
                    width: 100%;

                    b {
                        font-weight: 700;
                    }

                    .auth-icon {
                        font-size: 20px;
                        margin-right: 8px;
                    }
                }

                .mobile-login-btn {
                    width: 100%;
                    margin: 0 0.5rem;
                }

                border-bottom: 1px solid $baseGrayColor;
            }

            &.sidebar-section {
                display: flex;
                flex-direction: column;

                .sidebar-item {
                    text-decoration: none;
                    color: $baseDarkColor;
                    
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    .sidebar-item-icon {
                        font-size: 20px;
                    }

                    border-bottom: 1px solid $baseGrayColor;
                    
                    width: 100%;
                    padding: 0.7rem 1.5rem;

                    font-weight: 500;

                    &:hover, &:focus, &:active {
                        opacity: 0.7;
                    }

                    &.cart {
                        position: relative;

                        .cart-counter {
                            position: absolute;
                            top: 5px;
                            left: 40px;
                
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            
                            width: 15px;
                            height: 15px;
                            border-radius: 50%;
                
                            font-size: 10px;
                            font-weight: 600;
                
                            background-color: $baseDarkColor3;
                            color: $baseLightColor2;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 768px) {
        
    }
}