$textColor: var(--base-dark-color);
$backgroundColor: var(--base-primary-color);

$baseDarkColor3: var(--base-dark-color-3);
$baseLightColor2: var(--base-light-color-2);

.top-menu-bar {
    // width: 250px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    // gap: 25px;

    .top-menu-item {
        font-size: 25px;
        margin-left: 21px;

        background-color: rgba(0, 0, 0, 0);
        color: $textColor;
        border: none;
        outline: none;

        position: relative;

        .cart-counter {
            position: absolute;
            top: -5px;
            right: -10px;

            display: flex;
            align-items: center;
            justify-content: center;
            
            width: 13px;
            height: 13px;
            border-radius: 50%;

            font-size: 8px;
            font-weight: 600;

            background-color: $baseDarkColor3;
            color: $baseLightColor2;
        }

        &.username {
            margin-left: 7px;
            font-size: 12px;
        }

        #auth-dropdown,
        #info-dropdown {
            &::after {
                display: none;
            }
        }

        .dropdown-menu {
            // top: 100%;
            left: initial;
            border-radius: 0;
            right: 0;

            font-size: 12px;

            .dropdown-item {
                padding-top: 0.2rem;
                padding-bottom: 0.5rem;
            }
        }
    }

    @media (max-width: 900px) {
        // width: 200px;
    }

    @media (max-width: 768px) {
        width: 150px;
    }
}