.homepage {
    padding-top: 125px;

    .homepage-header {
        margin-bottom: 3.5rem;
    }

    // @media (max-width: 768px) {
    //     padding-top: 0;
    // }
}