$baseLightColor2: var(--base-light-color-2);
$baseDarkColor: var(--base-dark-color);
$baseGrayColor: var(--base-gray-color);
$baseGrayColor3: var(--base-gray-color-3);

$dangerColor: var(--base-danger-color);

.address-list-container {
    .address-list-title {

    }

    .address-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 1rem;

        padding: 1rem 0;

        .address-item-container {
            height: 150px;
            width: 100%;

            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            gap: 1rem;

            .address-item {
                width: 85%;
            
                cursor: pointer;
            }

            @media (max-width: 600px) {
                height: fit-content;

                .address-item {
                    gap: 1rem;

                    .address-item-header {
                        flex-direction: column;
                        gap: 0.3rem;
                    }
                }
            }

            .btn-address-modify-container {
                width: 10%;

                .btn-address-modify {
                    font-weight: 600;
                    text-transform: uppercase;

                    background-color: rgba(0, 0, 0, 0);
                    outline: none;
                    border: none;

                    transition: all 0.3s ease;

                    &:hover, &:focus, &:active {
                        opacity: 0.6;
                    }

                    &.edit {

                    }

                    &.delete {
                        color: $dangerColor;
                    }
                }
            }
        }
    }

    .btn-open-address-form {
        width: 100%;
        margin-top: 15px;
    }
}
            
.address-item {
    padding: 1rem;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 2rem;

    border: 2px solid $baseGrayColor3;
    border-radius: 6px;

    &.focus {
        border-color: $baseDarkColor;
    }

    .address-item-header {
        font-weight: 500;

        display: flex;
        gap: 1rem;
    }

    .address-item-content {
        color: $baseGrayColor;

        .address-content-item {
            text-transform: capitalize;
        }
    }
}

.btn-address-form-container {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: stretch;

    gap: 1rem;
}

.account-modify-toast {
    top: initial;
    right: initial;

    .content {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .delete-desc-container {
            grid-area: text;
            font-weight: 500;
        }
    
        .delete-button-container {
            grid-area: button;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 10px;
        }
    }


}