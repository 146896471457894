.storefront-wrapper {
    margin: 0;
    padding: 1rem 1rem;
    width: 100%;

    .storefront-container {
        position: relative;
        
        .storefront-container-header {
            display: flex;
            justify-content: space-between;
    
            .storefront-name {
                font-weight: 700;
                text-transform: uppercase;
                margin-bottom: 1rem;
                font-size: 2rem;
            }
        }
    }

    @media (max-width: 768px) {
        padding: 1rem;
    }
}