$baseGrayColor: var(--base-gray-color);
$baseGrayColor3: var(--base-gray-color-3);

$baseLightColor: var(--base-light-color);
$baseLightColor2: var(--base-light-color-2);

$baseDarkColor: var(--base-dark-color);
$baseDarkColor2: var(--base-dark-color-2);
$baseDarkColor3: var(--base-dark-color-3);

.explore-now {
    width: 100%;
    // border: 10px solid black;
    height: 100vh;
    // margin: 75px 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4rem;

    .explore-now-title {
        font-weight: 700;
        font-size: 32px;
        text-align: center;
    }

    .btn-explore-now {
        text-decoration: none;
        color: $baseLightColor2;
        background-color: $baseDarkColor;

        border-radius: 40px;
        padding: 0.8rem 2.4rem;
        font-size: 21px;
        font-weight: 600;

        text-transform: uppercase;

        transition: all 0.2s ease;

        &:hover {
            background-color: $baseDarkColor3;
            border-color: $baseDarkColor3;
        }
    
        &.disabled {
            background-color: $baseGrayColor;
        }
    }
}