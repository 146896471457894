$baseColor1: var(--base-primary-color);
$baseColor2: var(--base-secondary-color);
$baseColor3: var(--base-primary-color-2);
$baseColor4: var(--base-secondary-color-2);

$primaryTextColor: var(--primary-text-color);

$whatsappColor1: #25d366;
$whatsappColor2: #ece5dd;

$whatsappColorHover1: #075e54;
$whatsappColorHover2: #dcf8c6;

.btn-pre-order {
    
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    text-decoration: none;
    color: $baseColor4;
    font-weight: 700;
    background: $whatsappColor1;
    color: $whatsappColor2;

    width: 46px;
    height: 46px;
    border-radius: 50%;

    .btn-pre-order-icon {
        font-size: 25px;
    }

    transition: all 0.5s ease-in-out;
    
    &:hover, &:focus, &:active {
        background: $whatsappColorHover1;
        color: $whatsappColorHover2;
    }

    &.is-fixed {
        position: fixed;
        z-index: 1001;
        bottom: 20px;
        right: 20px;

        align-self: center;

        @media screen and (max-width: 768px) {
            text-align: center;
            // right: initial;
            // bottom: 4rem;
            // font-size: 15px;
            // border-radius: 50%;
            padding: 0.7rem;
            // width: 95%;
            margin: 0 10px;

            .btn-pre-order-text {
                // display: none;
            }

            .btn-pre-order-icon {
                width: 40px;
            }
        }
    }
}