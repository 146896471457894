$whiteColor: var(--base-primary-color);
$buttonColor: var(--base-secondary-color);
$brownColor: var(--base-brown-color);

.article-detail-page {
    padding-top: 150px;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 5rem;
    min-height: 600px;

    display: flex;
    justify-content: space-around;
    gap: 5rem;

    .copy-toast {
        position: fixed;
        right: 5px;
        top: 120px;
        z-index: 1003;

        background-color: $brownColor;
        color: $whiteColor;

        .copy-toast-body {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    background-color: $whiteColor;

    .article-data {
        width: 60%;
        margin: 0 auto;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 3rem;

        .article-header {
            .content-title {
                font-weight: 700;
                
            }

            .content-publisher {
                font-size: 15px
            }

            .content-publish-time {
                font-size: 13px;
            }
        }

        .article-image {
            width: 100%;
            min-width: 500px;

            img {
                width: 100%;
            }

            .media-source {
                padding: 5px;
                width: 100%;

                font-size: 0.7rem;

                font-style: italic;
                word-break: break-all;
                
                background-color: $brownColor;
                color: $whiteColor;
            }
        }

        .article-content {
            width: 100%;
            
            .content-text {
                margin-top: 5px;

                ul, ol {
                    width: 100%;

                    li {
                        white-space: break-spaces;
                    }
                }
            }

            .content-btn-share-container {
                width: 100%;
                display: flex;
                justify-content: flex-end;

                margin-top: 80px;

                .btn-share {
                    padding: 0.7rem 1.5rem;
                    font-size: 1rem;
                    font-weight: 600;

                    border: none;
                    outline: none;
                    border-radius: 30px;

                    background: $brownColor;
                    color: $whiteColor;

                    transition: all 0.5s ease;

                    &:hover, &:active, &:focus {
                        opacity: 0.7;
                    }
                }
            }
        }

        min-width: 600px;
    }

    .other-articles {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        width: 40%;

        .other-articles-header {
            border-bottom: 3px solid $brownColor;
        }

        .other-article-item {
            display: flex;
            gap: 1rem;
            transition: all 0.4s ease;

            &:hover {
                opacity: 0.7;
                cursor: pointer;
            }

            .article-thumbnail {
                min-width: 200px;
                max-width: 200px;

                img {
                    width: 100%;
                }
            }

            .article-content {
                .content-publisher {
                    font-size: 15px
                }
    
                .content-publish-time {
                    font-size: 13px;
                }
            }
        }
    }

    @media (max-width: 1175px) {
        flex-direction: column;
        padding-bottom: 3rem;
        
        .article-data {
            width: 100%;
            padding-bottom: 1rem;
            
        }

        .other-articles {
            width: 90%;
            margin: 0 auto;
        }
    }

    @media (max-width: 800px) {
        .article-data {
            min-width: 0;

            .article-image {
                min-width: 0;
            }
        }
    }

    @media (max-width: 700px) {
        padding-left: 2rem;
        padding-right: 2rem;

        .other-articles {
            .other-article-item {
                .article-thumbnail {
                    min-width: 150px;
                    max-width: 150px;
                }

                .article-content {
                    .content-title {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    @media (max-width: 460px) {
        padding-left: 1rem;
        padding-right: 1rem;

        .other-articles {
            .other-article-item {
                .article-thumbnail {
                    min-width: 120px;
                    max-width: 120px;
                }
            }
        }
    }
}