.comparison-page {
    width: 100%;
    // min-height: 100vh;
    padding-top: 120px;

    .template-container {
        margin: 15px 2rem;
        padding: 1rem;
        border: 2px solid black;
        min-height: 70vh;
        
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-around;
        gap: 1rem;

        .template-box {
            border: 1px solid black;
            width: 100%;
            height: 100%;
            padding: 1rem;

            display: flex;
            flex-direction: column;
            gap: 1rem;

            background: none;
            outline: none;

            &.empty {
                cursor: pointer;

                &:hover, &:active {
                    opacity: 0.7;
                }
            }

            .btn-close-container {
                width: 100%;
                display: flex;
                justify-content: flex-end;
            }

            .photo-container {
                width: 100%;
                margin-bottom: 1rem;
            }

            .product-name {
                font-weight: 600;
            }
        }
    }

}

.catalog-modal {
    .modal-search-bar {
        width: 100%;

        .search-icon {
            top: 11px;
            width: 14px;
        }

        .search-box {
            font-size: 14px;
            padding: 8px 12px 8px 38px;
            margin-bottom: 15px;
        }
    }
}