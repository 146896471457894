$baseGrayColor: var(--base-gray-color);
$baseGrayColor3: var(--base-gray-color-3);

$baseLightColor: var(--base-light-color);
$baseLightColor2: var(--base-light-color-2);

$baseDarkColor: var(--base-dark-color);
$baseDarkColor2: var(--base-dark-color-2);
$baseDarkColor3: var(--base-dark-color-3);

.photo-container {
    width: 45%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 30px;

    .photo-main-container {
        width: 70%;

        img {
            width: 100%;
            box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.1);
        }
    }

    .photo-carousel-wrapper {
        width: 70%;
        position: relative;
        
        .photo-carousel-container {
            padding: 10px 0;
            position: static;
        
            .react-multiple-carousel__arrow {
                background-color: $baseLightColor;
                box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.31);
        
                &::before {
                    color: $baseDarkColor3;
                    font-weight: 600;
                }
        
                min-width: 35px;
                min-height: 35px;
                border-radius: 50%;
    
                &--left {
                    left: -20px;
                }
    
                &--right {
                    right: -20px;
                }
            }
        
            .photo-carousel-holder {
                display: flex;
                align-items: center;
                justify-content: center;
        
                img {
                    width: 90%;
                    box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.1);
                    cursor: pointer;
                    // border-radius: 10px;
                }
            }
        
            @media (max-width: 1200px) {
                // width: 100%;
        
                .photo-carousel-holder {
                    img {
                        // width: 80%;
                    }
                }
            }
        
            @media (max-width: 900px) {
                .photo-carousel-holder {
                    img {
                        // width: 85%;
                    }
                }
            }
            
            @media (max-width: 900px) {
                width: 100%;
            }
        }
    }

}
