$baseDarkColor: var(--base-dark-color);
$baseGrayColor: var(--base-gray-color);
$baseGrayColor3: var(--base-gray-color-3);

$errorColor: var(--base-danger-color);

.form-items {
    margin-bottom: 1.5rem;

    .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating):not(.input-group-text) {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    .form-control,
    .form-select {
        background-color: rgba(0, 0, 0, 0);
        outline: none;
    
        border: 2px solid $baseGrayColor3;
        border-radius: 6px;
    
        padding: 0.5rem 1rem;
    
        font-size: 14px;
    
        &.is-filled,
        &:focus,
        &:active,
        &:hover {
            border-color: $baseDarkColor;
            outline: 0;
            box-shadow: none;
            transition: all 0.5s ease;
        }
    
        &.is-invalid {
            border-color: $errorColor;

            &:focus,
            &:active,
            &:hover {
                box-shadow: none;
                transition: all 0.5s ease;
            }
        }
    }

    .form-control {
        &.file-upload {
            border-style: dashed;
            max-width: unset;

            min-height: 150px;
            max-height: 600px;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;

            color: $baseGrayColor;

            .icon {
                font-size: 27px;
                margin-bottom: 10px;
            }

            .label {
                font-size: 15px;
                margin: 0;
            }

            .types {
                font-size: 12px;
            }

            .file-upload-image {
                max-height: 500px;
            }
        }
    }

    .invalid-feedback {
        color: $errorColor;
    }
}
