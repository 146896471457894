.terms-and-condition {
    padding-top: 150px;

    width: 95%;
    margin: auto;

    .text-link {
        color: #000;
        font-weight: 600;

        &:hover,
        &:focus,
        &:active {
            opacity: 0.8;
        }
    }

    &-intro {
        margin: 1.7rem auto 1.5rem;

        h1 {
            font-size: 2rem;
            margin-bottom: 1.5rem;
            font-weight: 600;
        }

        p {
            font-size: 14px;
        }
    }

    &-content {
        line-height: 1.7rem;

        ol {
            list-style-type: upper-latin;
            padding: 0 1.5rem;

            .terms-and-condition-content-item {
                margin-bottom: 2rem;
                font-size: 13px;

                & > li {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 60px;
                }
                
                .item-paragraph {
                    // margin: 0;
                }

                .item-lists-title {
                    margin-bottom: 0.5rem;
                }

                .item-lists {
                    margin-bottom: 1rem;
                    padding: 0 1.2rem;
                    list-style-type: decimal;
                    // list-style-type: disc;

                    & > .item-lists {
                        list-style-type: lower-latin;

                        & > .item-lists {
                            list-style-type: lower-roman;
                        }
                    }
                }
            }
        }

    }
}