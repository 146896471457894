$secondaryTextColor: var(--base-dark-color-3);
$buttonCarouselColor: var(--base-gray-color);
$buttonSeeMore: var(--base-dark-color);

$baseLightColor: var(--base-light-color);

.navigation-container {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    gap: 1rem;

    .btn-custom-carousel-navigation {
        font-size: 23px;
        border: none;
        outline: none;
        background-color: $baseLightColor;

        width: 40px;
        height: 40px;
        border-radius: 50%;

        .icon {
            color: $buttonCarouselColor;
        }

        &.disabled {
            opacity: 0.6;
        }
    }

    .btn-see-more {
        border: 3px solid $buttonSeeMore;
        border-radius: 30px;
        color: $buttonSeeMore;
        padding: 0.2rem 2rem;

        font-weight: 700;
        font-size: 0.8rem;
        
        text-transform: uppercase;
        text-decoration: none;

        display: flex;
        align-items: center;
    }

    @media (max-width: 768px) {
        top: 35px;
        left: 0;
    }

    @media (max-width: 330px) {
        .btn-see-more {
            padding: 0.2rem 1rem;
            font-size: 0.7rem;
        }
    }
}

.product-carousel-item-container {
    padding: 0.8rem 0rem 0.8rem;
    margin: 0.6rem 0 2rem;

    .btn-see-more {
        position: absolute;
        right: 0;
        top: 0;
    }

    .product-carousel-item-holder {
        display: flex;
        align-items: flex-start;
        justify-content: center;

        // max-width: 200px;
    }
}
